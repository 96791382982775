// @ts-strict-ignore
import { debounce } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { useActions } from 'src/components/buttons/followButton/useActions';
import { useButtonText } from 'src/components/buttons/followButton/getUnfollowText';
import Props from 'src/components/buttons/followButton/types';
import { FollowIcon } from 'src/components/buttons/followButton/followIcon';
import i18n from 'src/utils/translate';
import Button from 'src/components/buttons/button';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';
import { useDispatch } from 'src/store/store';
import { useIsLoggedIn } from 'src/utils/hooks/useIsLoggedIn';

export default function FollowButton({
  followText = i18n.t('Join'),
  buttonType = 'secondary',
  className,
  clickMetadata = { element: Element.Follow, section: Section.Untracked },
  community = null,
  dataTestId = 'follow-button',
  followType,
  hasIcon = true,
  isDisabled = false,
  isFollowing,
  isFullWidth = false,
  onFollowClick = () => { },
  onSignupSuccess,
  postId,
  role,
  size,
  tabIndex,
  userId,
  username,
  unFollowText,
}: Props) {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const [isHover, setIsHover] = useState(false);
  const { followAction, unfollowAction } = useActions({ followType, postId, userId, communityName: community?.name, communitySlug: community?.slug, username });
  const { buttonText, ariaLabel } = useButtonText({ followType, isHover, hasIcon, followText, unFollowText, isFollowing });

  const onClick = debounce(() => {
    if (!isLoggedIn) {
      dispatch(showSignupModal({ community, onSignupSuccess }));
      trackFootfallEvent(Event.Clicked, { clickType: 'sign-up', clickMetadata });
      return;
    }

    if (isFollowing) {
      unfollowAction();
    } else {
      followAction();
    }

    onFollowClick();
  }, 300);

  return (
    <StyledButton
      ariaLabel={ariaLabel}
      className={className}
      dataTestId={dataTestId}
      disabled={isDisabled}
      fullWidth={isFullWidth}
      role={role}
      size={size}
      tabIndex={tabIndex}
      type={buttonType}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {hasIcon && <FollowIcon isFollowing={isFollowing} isHover={isHover} />}
      {buttonText}
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  display: flex;
  gap: 5px;
  justify-content: center;
`;
