// @ts-strict-ignore
import Link from 'next/link';
import { ReactNode, SyntheticEvent } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import Button from 'src/components/buttons/button';
import { showSignupModal, showModal } from 'src/modules/shared/modal/actions';
import { followCommunity } from 'src/modules/community/shared/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';
import { ModalType } from 'src/constants/modalTypes';
import { showGuidelines } from 'src/modules/community/shared/components/communityGuidelinesModal';
import { userHasPermission, useIsCommunityMember } from 'src/utils/context';
import { setAlert } from 'src/components/alert/actions';
import { Permission } from 'src/constants/types';
import { ModalProps } from 'src/modules/shared/modal/components/defaultModal';
import FollowSuccessMessage from 'src/modules/community/shared/components/alert/followSuccessMessage';

export function WriteLink({ children, trackingSection, className, onClick }: Props): JSX.Element {
  const router = useRouter();
  const dispatch = useDispatch();
  const { currentCommunity, currentUser } = useSelector(({ context }) => context);
  const isCommunityMember = useIsCommunityMember();

  const as = currentCommunity ? `/${currentCommunity.slug}/posts/write` : '/write';
  const href = currentCommunity ? `/community/write?communitySlug=${currentCommunity.slug}` : '/community/write';

  function showSignup(trackingSection: Section) {
    dispatch(showSignupModal({ community: currentCommunity }));
    trackFootfallEvent(Event.Clicked, {
      clickType: 'sign-up',
      clickMetadata: { section: trackingSection, element: Element.WritePost },
    });
  }
  function showJoinCommunity(e) {
    const modalProps: ModalProps = {
      acceptButtonText: i18n.t('Join'),
      cancelButtonText: i18n.t('Cancel'),
      children: i18n.t('You need to join this community to be able to write a post or ask a question'),
      title: i18n.t('Write a post'),
      onAccept: async () => {
        await dispatch(followCommunity({ successMessage: <FollowSuccessMessage communityName={currentCommunity.name} /> }));
        if (showGuidelines(currentCommunity)) {
          e.preventDefault();
          showGuidelinesModal();
        } else {
          router.push(`/community/write?communitySlug=${currentCommunity.slug}`, `/${currentCommunity.slug}/posts/write`);
        }
      },
    };
    dispatch(showModal({ modalType: ModalType.Default, modalProps }));
  }
  function restricted() {
    dispatch(setAlert(i18n.t(`You have been restricted in this community. Please contact this community's administrator to find out why.`)));
  }
  function showGuidelinesModal() {
    dispatch(showModal({ modalType: ModalType.CommunityGuidelines, modalProps: { redirect: true } }));
  }

  function onClickExtended(e) {
    onClick?.(e);
    if (!currentUser) {
      e.preventDefault();
      showSignup(trackingSection);
    } else if (currentCommunity && !isCommunityMember) {
      e.preventDefault();
      showJoinCommunity(e);
    } else if (currentCommunity && !userHasPermission(currentUser, Permission.CreatePost)) {
      e.preventDefault();
      restricted();
    } else if (currentCommunity && showGuidelines(currentCommunity)) {
      e.preventDefault();
      showGuidelinesModal();
    }
  }

  return (
    <Link
      as={as}
      className={className}
      href={href}
      onClick={onClickExtended}
    >
      {children}
    </Link>
  );
}

type Props = {
  children?: ReactNode;
  trackingSection?: Section;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
}
export default function WriteButton({
  children,
  trackingSection,
}: Props): JSX.Element {
  return (
    <WriteLink trackingSection={trackingSection}>
      <Button dataTestId="write-button" icon="pencil">
        {children}
      </Button>
    </WriteLink>
  );
}
